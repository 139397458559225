<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>Casey Brown</h1>

          <p>
            Casey is a Christian Academy of Louisville alumni, earning 3x
            All-District, 2x All Region and All-Louisville Metro Team. In 2019,
            Casey signed to play at Midway University earning the starting
            catcher position the last 3 seasons and 2nd Team All Confernece in
            the River States Conference (2022). Casey plans to tranfer to
            Indiana Universtiy Southeast in the fall 2023.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" lg="4">
          <v-card max-width="400" max-height="400" hover>
            <v-img
              class="align-center"
              max-width="400"
              max-height="400"
              :src="CaseyBrown"
            ></v-img>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" lg="8">
          <h2>Train with Casey</h2>

          <p>
            Casey focuses on the Big 3 of Catching:

            <br />
            - Receiving

            <br />
            - Blocking

            <br />
            - Throwing

            <br />
          </p>

          <p>As well as mobility, footwork and mental side of the catching.</p>

          <h3>Price</h3>
          <p>
            $40 - 30 minutes

            <br />
            $75 - 60 minutes
          </p>

          <v-btn href="tel:5029307325" color="primary">Call Now</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col> </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-img class="dark align-center mt-4" :src="PDTrain"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CaseyBrown from '@/assets/images/caseycatching.png'
import PDTrain from '@/assets/images/PDTrain.svg'

export default {
  data() {
    return {
      CaseyBrown: CaseyBrown,
      PDTrain: PDTrain,
    }
  },
}
</script>

<style></style>
